<template>
  <div class="page">
    <!-- <ls-title title="个人资料"></ls-title> -->
    <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="140px" class="demo-ruleForm" >
    <div style="background-color: white;">
      <div class="box">

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="deviceOn">
                <template #label><span class="title">设备预警</span></template>
                <span class="content" v-if="!editShow && this.ruleForm.deviceSwitch==1">开</span>
                <span class="content" v-if="!editShow && this.ruleForm.deviceSwitch==0">关</span>
                <el-switch v-if="editShow" @change="deviceChange" style="display: block;margin-top: 10px;width:150px;" v-model="noticData.deviceSwitch" active-color="#13ce66" active-text="开启" inactive-text="关闭"> </el-switch>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="healthOn">
                <template #label><span class="title">健康预警</span></template>
                <span class="content" v-if="!editShow && this.ruleForm.healthSwitch==1">开</span>
                <span class="content" v-if="!editShow && this.ruleForm.healthSwitch==0">关</span>
                <el-switch v-if="editShow" @change="healthChange" style="display: block;margin-top: 10px;width:150px;" v-model="noticData.healthSwitch" active-color="#13ce66" active-text="开启" inactive-text="关闭"> </el-switch>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="noticeType">
                <template #label><span class="title">预警方式</span></template>
                <div style="display:flex;align-items: center;" v-if="!editShow">
                    <el-checkbox label="电话通知" v-model="noticData.voiceSwitch" disabled></el-checkbox>
                    <el-checkbox label="短信通知" v-model="noticData.smsSwitch" disabled></el-checkbox>
                    <el-checkbox label="公众号通知" v-model="noticData.accountSwitch" disabled></el-checkbox>
                </div>
                <div style="display:flex;align-items: center;" v-if="editShow">
                    <el-checkbox label="电话通知" v-model="noticData.voiceSwitch" :disabled=isDisabled></el-checkbox>
                    <el-checkbox label="短信通知" v-model="noticData.smsSwitch" :disabled=isDisabled></el-checkbox>
                    <el-checkbox label="公众号通知" v-model="noticData.accountSwitch" :disabled=isDisabled></el-checkbox>
                </div>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="name">
                <template #label><span class="title">通知人名</span></template>
                <span class="content" v-if="!editShow">{{ruleForm.name}}</span>
                <el-input v-if="editShow" v-model="ruleForm.name" placeholder="请输入通知人姓名" maxlength="10" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>

        <el-row class="row">
          <el-col :span="24">
              <el-form-item prop="warningPhone">
                <template #label><span class="title">通知号码</span></template>
                <span class="content" v-if="!editShow">{{ruleForm.warningPhone}}</span>
                <el-input v-if="editShow" v-model="ruleForm.warningPhone" placeholder="请输入预警通知人电话"  maxlength="11" show-word-limit class="input"></el-input>
              </el-form-item>
          </el-col>
        </el-row>


        <el-row class="row">
          <el-col :span="24">
            <div style="display:flex;align-items: center;">
              <el-button v-show="!editShow&role!=1" type="primary" style="float:right;" size="small" @click="editPage">编辑</el-button>
              <el-button v-show="editShow&role!=1" type="primary" style="float:right;" size="small" @click="closeEdit" plain>返回</el-button>
              <el-button v-show="editShow&role!=1" type="primary" style="float:right;" size="small" @click="submitForm('ruleForm')">提交</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    </el-form>
  </div>
</template>

<script>
import {
  getWarningNoticeInfo,
  editWarningNoticeInfo
} from '../../../api/api'
import session from "../../../store/store";
export default {
  data() {
    return {
      role:session.getLoginInfo().accountType,
      unitUuid:session.getCustomerData().unitUuid,
      editShow:false,
      ruleForm: {
        deviceSwitch:0,
        healthSwitch:0,
        smsSwitch:0,
        voiceSwitch:0,
        accountSwitch:0,
        name:'',
        warningPhone:'',
      },
      noticData: {
        deviceSwitch:false,
        healthSwitch:false,
        smsSwitch:false,
        voiceSwitch:false,
        accountSwitch:false,
      },
      isDisabled:true,
      rules: {
        name: [
          {required: true,message: '请输入姓名', trigger: 'blur',}
        ],
        warningPhone:[
          {required: true,min: 0,max: 11,message: "长度在 0 到 11 个数字", trigger: "blur"},
          {pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/, message: "请输入合法手机号", trigger: 'blur' }
        ],
      }

    
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.required(false)
    },
    required(yes) {
      this.rules.name[0].required = yes;
      this.rules.warningPhone[0].required = yes;
    },

    getParentData() {
      this.getWarningNoticeInfo()
    },

    getWarningNoticeInfo() {
      let param = {
        unitUuid:this.unitUuid,
        idcard:session.getCustomerData().idcard,
      }
      getWarningNoticeInfo(param).then(res => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.dataInfo;
          if(this.ruleForm.deviceSwitch == 1) {
            this.noticData.deviceSwitch = true;
          }else  {
            this.noticData.deviceSwitch = false;
          }
          if(this.ruleForm.healthSwitch == 1) {
            this.noticData.healthSwitch = true;
          }else  {
            this.noticData.healthSwitch = false;
          }
          if(this.ruleForm.smsSwitch == 1) {
            this.noticData.smsSwitch = true;
          }else  {
            this.noticData.smsSwitch = false;
          }
          if(this.ruleForm.voiceSwitch == 1) {
            this.noticData.voiceSwitch = true;
          }else  {
            this.noticData.voiceSwitch = false;
          }
          if(this.ruleForm.accountSwitch == 1) {
            this.noticData.accountSwitch = true;
          }else  {
            this.noticData.accountSwitch = false;
          }
        }
      })
    },

    editPage(){
      this.editShow = true
      if(this.ruleForm.deviceSwitch == 1 || this.ruleForm.healthSwitch) {
        this.isDisabled = false
      }
      this.required(true)
    },


    deviceChange(value) {
      if(value) {
        this.isDisabled = false
        this.ruleForm.deviceSwitch = 1
      }else {
        this.ruleForm.deviceSwitch = 0
      }
    },
    healthChange(value) {
      if(value) {
        this.isDisabled = false
        this.ruleForm.healthSwitch = 1
      }else {
        this.ruleForm.healthSwitch = 0
      }
    },

    submitForm(ruleName) {
      this.$refs[ruleName].validate((valid) => {
        if (valid) {
          this.editWarningNoticeInfo()
        } else {
          return false;
        }
      });
    },

    editWarningNoticeInfo() {

      if(this.noticData.smsSwitch) {
        this.ruleForm.smsSwitch = 1;
      }else {
        this.ruleForm.smsSwitch = 0;
      }

      if(this.noticData.voiceSwitch) {
        this.ruleForm.voiceSwitch = 1;
      }else {
        this.ruleForm.voiceSwitch = 0;
      }

      if(this.noticData.accountSwitch) {
        this.ruleForm.accountSwitch = 1;
      }else {
        this.ruleForm.accountSwitch = 0;
      }

      if(this.noticData.deviceSwitch) {
        this.ruleForm.deviceSwitch = 1;
      }else {
        this.ruleForm.deviceSwitch = 0;
      }

      if(this.noticData.healthSwitch) {
        this.ruleForm.healthSwitch = 1;
      }else {
        this.ruleForm.healthSwitch = 0;
      }


      var param = {
        unitUuid: this.unitUuid,
        idcard:session.getCustomerData().idcard,
        smsSwitch:this.ruleForm.smsSwitch,
        voiceSwitch:this.ruleForm.voiceSwitch,
        accountSwitch:this.ruleForm.accountSwitch,
        deviceSwitch:this.ruleForm.deviceSwitch,
        healthSwitch:this.ruleForm.healthSwitch,
        name:this.ruleForm.name,
        warningPhone:this.ruleForm.warningPhone
      }

      editWarningNoticeInfo(param).then(res => {
        if (res.data.code == 200) {
          this.$message.success({ message: '修改成功'});
          this.closeEdit();
        } else {
          this.$message.error({ message: res.data.message});
        }
      })
    },


    closeEdit() {
      this.editShow = false;
      this.required(false)
    },
  }
}
</script>

<style lang="scss" scoped>

.page {
  padding: 0px 0px;
}
.el-form-item {
  margin-bottom: 0px !important;
  margin-left: -19px;
}
.input {
  width: 90%;
}
hr {
  border-color: #f4f4f526;
}
.page {
  padding: 0px 20px;
}
.title {
  margin-right: 25px;
  font-size: 16px;
  color: #8D8D8D;
}
.box {
  // padding: 20px;
  width: 75%;
  background-color: white;
}
.content {
  font-size: 16px;
  margin-left: 10px;
}
.row {
  margin: 20px 0px 20px 0px;
}
</style>