<template>
  <div class="page">
    <ls-title title="个人资料"></ls-title>
    <div class="box">
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="first">
          <UserBaseInfo ref="baseView" ></UserBaseInfo>
        </el-tab-pane>
        <el-tab-pane label="预警通知" name="second">
          <UserNoticeSet ref="noticeView"></UserNoticeSet>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import UserBaseInfo from "./UserBaseInfo.vue"
import UserNoticeSet from './UserNoticeSet.vue'
export default {
  components: {
    UserBaseInfo,
    UserNoticeSet
  },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    handleClick(tab) {
      if(0 == tab.index) {
        setTimeout(() => {
          this.$refs.baseView.getParentData();
        }, 200);
      }else if(1 == tab.index) {
        setTimeout(() => {
          this.$refs.noticeView.getParentData();
        }, 200);
      }
    },
  },
};
</script>

<style scoped>
.page {
  padding: 0px 20px;
}
.box {
  padding: 20px 20px;
  background-color: white;
  border-radius: 4px;
}
</style>
<style>
.el-tabs__item {
    font-size: 18px;
}

.el-tabs__nav-wrap::after {
  position: static !important;
}
</style>